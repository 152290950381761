.account-page-container {
    background-color: #56a09b;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('../../Images/homeBackgroundImage.png');
    background-size: 100%;
    padding-top: 4%;
}

.logged-into-container {
    color: #DDD6B3;
    background-color: #30271e;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 8px 8px 0;
    border-radius: 10px;
    width: 90%;
    margin-top: 4%;
    height: 8vh;
}

.account-folder-container {
    margin-top: 4%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}

.account-folder {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-image {
    position: absolute;
    top: 0;
    margin-top: 4%;
    margin-left: 6.5%;
    height: 70.974%;
    width: 64.104%;
}

.upload-photo {
    position: absolute;
    bottom: 0;
    height: 18%;
    margin-bottom: 8%;
    width: 54%;
    margin-left: 6%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.upload-button {
    /* position: absolute;
    bottom: 0; */
    background-color: #30271e;
    color: #DDD6B3;
    border: none;
    border-radius: 5px;
    font-size: 60px;
    width: 30%;
    margin-top: 0;
    height: 4vh;
    font-size: 22px;
    /* right: 0;
    margin-right: 8%;
    margin-bottom: 0.5%; */
}

.secret-code-container {
    margin-top: 7%;
    width: 100%;
    margin-left: 2%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.secret-code {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.secret-code-text {
    position: absolute;
    top: 0;
    width: 80%;
    margin-left: 0;
    display: flex;
    text-align: center;
    justify-content: center;
}

.secret-phase-input {
    background-color: transparent;
    border-style: none;
    border-color: transparent;
    width: 40%;
    font-size: 27px;
    margin-left: 5%
}

.secret-phase-input:focus :active {
    border-style: none;
}

input {
    border: none;
}

.secret-phase-button {
    background-color: #30271e;
    color: #DDD6B3;
    border: none;
    border-radius: 10px;
    height: 7vh;
    font-size: 20px;
    width: 25%;
    margin-right: 5%;
    text-align: center;
}