.top-landing {
    background-color: #0063da;
    border-radius: 20px;
    min-height: 17vh;
    width: 90%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    flex-direction: column;
    
}

.heading {
    text-align: left;
    width: 80%;
    height: 20%;
}

.what-is {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 80%;
    height: 60%;
}