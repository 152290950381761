.landing-container {
    height: 100vh;
    background-color: #56a09b;
    background-image: url('../../Images/homeBackgroundImage.png');
    background-size: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    padding-bottom: 30%;
}

.landing-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 40%;
    padding-top: 40%;
    width: 98%;
}

.title-header {
    width: 370px;
    display: flex;
    flex-direction: column;
    transform-origin: 0 0;
    transform: skew(-12deg);
    text-align: right;
}

.title {
    font-size: 150px;
    margin-bottom: 0;
    transform-origin: 0 0;
    transform: rotate(-12deg);
}

.title-bottom {
    margin-top: -8%;
    margin-right: 2%
}

.camera {
    z-index: 2;
    margin-top: -5%;
    max-height: 50%;
}

.folder {
    width: 100%;    
}

.about-container {
    margin-top: 40%;
    width: 96%;
    margin-left: 2%;
    position: relative;
    display: flex;
    justify-content: center;
}

.about-text {
    position: absolute;
    top: 0;
    width: 80%;
    margin-left: 7%;
}

.about-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
}

.login-container {
    position: absolute;
    bottom: 0;
    width: 86%;
    margin-bottom: 4%;
    margin-left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-text {
    position: absolute;
    width: 80%;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
}

