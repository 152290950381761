
.home-page-container {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 78vh;
    margin-top: 0;
}

.player-remain-container {
    color: white;
    background-color: #0063da;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-left: 5%;
    border-radius: 10px;
    width: 90%;
    height: 12vh;
}
.player-count {
    color: white;
    padding: 5px;
    margin-left: 5px;
    border-radius: 10px;
    display: flex;
    text-align: center;
    background-color: #0063da;
}

.count h1 {
    margin-bottom: 0;
}

.your-target-container {
    background-color: white;
    color: #d3573d;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    min-height: 10vh;
}

.your-target {
    background-color: white;
    padding: 5px 15px 5px 15px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.your-target h1 {
    font-size: 45px;
}

.target-image-container {
    background-color: #d3573d;
    padding: 15px;
    border-radius: 15px;
    height: 35vh;
    width: 35vh;
    /* border-left: 7px solid #2f261d;
    border-bottom: 7px solid #2f261d; */

}
.target-image {
    height: 100%;
    border-radius: 15px;
}

.target-description-container {
    background-color: white;
    color: #d3573d;
    padding: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 8vh;
    width: 32vh;
    /* border-left: 7px solid #2f261d;
    border-bottom: 7px solid #2f261d; */
}



.home-container {
    height: 92vh;
    position: relative;
}

.background-image {
    width: 100%;
    position: absolute;
    z-index: 1;
}

.player-count-filling {
    z-index: 2;
    top: 7%;
    left: 76%;
    position: absolute;
    width: 16.5%;
    height: 8.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100%;
}

.player-count-filling h1 {
    margin-bottom: 0;
}


.kill-code-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}


