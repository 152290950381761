.mission-page-container {
    background-color: #56a09b;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    background-image: url('../../Images/homeBackgroundImage.png');
    background-size: 100%;
    overflow-y: hidden !important;
}

.players-remaining-container {
    color: #DDD6B3;
    background-color: #30271e;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 8px 8px 0;
    border-radius: 10px;
    width: 90%;
    height: 10vh;
}
.players-count {
    background-color: #56a09b;
    border-radius: 10px;
    height: 100%;
    width: 18%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #DDD6B3;
}


.target-container {
    background-color: #56a09b;
    color: #DDD6B3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-color: #30271e;
    border-style: solid;
    border-width: 7px;
    border-radius: 10px;
    height: 14vh;
}

.image-target-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}

.target-folder {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.target-image-mission {
    position: absolute;
    top: 0;
    margin-top: 4%;
    margin-left: 6.9%;
    height: 70.974%;
    width: 64.104%;
}

.target-description-mission {
    position: absolute;
    bottom: 0;
    height: 18%;
    margin-bottom: 1%;
    width: 54%;
    margin-left: 8.4%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.name-container {
    display: flex;
    width: 50%;
}

.eliminate-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eliminate {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eliminate-text {
    position: absolute;
    width: 80%;
    display: flex;
    text-align: center;
    justify-content: center;
}

.personal-code-container {
    width: 70%;
    display: flex;
    height: 8vh;
    border: solid #30271e 7px;
    border-radius: 10px;
    background-color: #56a09b;
}

.personal-code-left {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 60%
}

.personal-code-middle {
    width: 7px;
    height: 101%;
    background-color: #30271e;
}

.kill-code-input {
    border: #30271e;
}
  
.kill-code-input:hover {
    text-decoration: none;    
    color: '#DDD6B3';
}

.eliminate-button {
    align-items: center;
    border-style: none;
    background-color: transparent;
    border-radius: 5px;
    color: #30271e;
    font-size: 40px;
}