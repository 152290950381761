.login-page-container {
    background-color: #56a09b;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-image: url('../../Images/homeBackgroundImage.png');
    background-size: 100%;
    overflow-y: hidden;
    padding-top: 5%;
}

.login-info {
    width: 90%;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-around;
}

.login-info p{
    font-size: 2ch;
}