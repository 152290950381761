.navbar {
    background-color: #56a09b;
}


.navbar-2 {
    background-color: #ffffff;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
}

.logo {
    height: 5vh;
}

.navbar-2 a {
    text-decoration:  none;
}

.logout-button {
    background-color: #0063da;
    color: white;
    border-style: none;
    border-radius: 5px;
    padding: 7.5px;
}

.navbar-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 5vh;
}

.navbar-button-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-button {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-text {
    position: absolute;
    display: flex;
    text-align: center;
    justify-content: center;
}