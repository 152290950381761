.rules-page-container {
    background-color: #56a09b;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 2%;
    flex-direction: column;
    background-image: url('../../Images/homeBackgroundImage.png');
    background-size: 100%;
    padding-bottom: 10%;
    min-height: 100vh;
    padding-top: 4%;
    
}

.rules-container {
    margin-top: 5%;
    width: 96%;
    position: relative;
    display: flex;
    justify-content: center;
}

.rules-text {
    position: absolute;
    top: 0;
    width: 80%;
    margin-left: 5%;
}

.rules-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 3%;
}

.rules-text p {
    margin-bottom: 0;
}
